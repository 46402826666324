import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Form from "../components/form/form"

const Contact = ({ location }) => {
  return (
    <Layout location={location} title="Contact GVF">
      <SEO title="Contact GVF" />
      <section id="contact-page" className="page">
        <div className="container text-white">
          <div className="content bg-black p-5">
            <div className="section-content">
              <div className="row">
                <Form
                  form_name="Newsletter Form"
                  form_id="5dc51b6b4331612c88c12538"
                  classname="form-newsletter"
                  method="post"
                  action="/thank-you"
                >
                  <div className="form-row">
                    <div className="col-8 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Name"
                          id="inputName"
                          required={true}
                          placeholder="Enter Your Name"
                        />
                      </div>
                    </div>
                    <div className="col-8 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          name="Email Address"
                          id="inputEmail_address"
                          required={true}
                          placeholder="Enter Your Email"
                        />
                      </div>
                      <small className="text-dark form-text mt-2">
                        We will never share your email with anyone else.
                      </small>
                    </div>
                    <div className="col-8 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="organization"
                          id="inputOrganization"
                          required={true}
                          placeholder="Enter Your Organization"
                        />
                      </div>
                    </div>
                    <div className="col-8 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="number"
                          name="phone"
                          id="inputPhone"
                          required={true}
                          placeholder="Enter Your Phone"
                        />
                      </div>
                    </div>
                    <div className="col-8 mb-3">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows="4"
                          name="Message"
                          id="inputMessage"
                          required={true}
                          placeholder="Enter Your Message"
                        />
                      </div>
                    </div>

                    <div className="col-8 mb-3">
                      <div className="form-group">
                        <div className="col-sm-10">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="interest"
                              id="everyclassroom"
                              value="Planet in Every Classroom"
                            />
                            <label
                              className="form-check-label"
                              for="everyclassroom"
                            >
                              Planet in Every Classroom
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="interest"
                              id="earthflag"
                              value="Flying the Earth Flag"
                            />
                            <label className="form-check-label" for="earthflag">
                              Flying the Earth Flag
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="interest"
                              id="everychurch"
                              value="Earth in Every Church"
                            />
                            <label
                              className="form-check-label"
                              for="everychurch"
                            >
                              Earth in Every Church
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="interest"
                              id="earthday2020"
                              value="Earth Day 2020"
                            />
                            <label
                              className="form-check-label"
                              for="earthday2020"
                            >
                              Earth Day 2020
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="interest"
                              id="volunter"
                              value="Volunteering with Global View Earth"
                            />
                            <label className="form-check-label" for="volunter">
                              Volunteering with Global View Earth
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="interest"
                              id="products"
                              value="Products & Fine Art"
                            />
                            <label className="form-check-label" for="products">
                              Products & Fine Art
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-8">
                      <div className="webriq-recaptcha" />
                    </div>

                    <div className="col-8">
                      <div className="form-group">
                        <button className="btn btn-normal mt-4" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact

// export const contactPageQuery = graphql`
//   query contactPageQuery {
//     site {
//       siteMetadata {
//         title
//         author
//         description
//       }
//     }
//   }
// `
